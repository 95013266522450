import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    HeatmapLayer,
    Polyline,
    Polygon,
} from "@react-google-maps/api";
import { ActionIcon, rem } from "@mantine/core";
import { IconAnalyze, IconCurrentLocation } from "@tabler/icons-react";
import authService from "../../api-authorization/AuthorizeService";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useEffectOnce } from "react-use";
import Carousel from "react-awesome-slider";
import { addRoute, selectRouteById } from "../../../store/runSlice";
import { debounce, generateRandomColor } from "../../../utils";

const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
    zIndex: 50,
    borderRadius: "25px",
};

const MarkerAI = ({
    runId,
    handleActiveMarker,
    setActiveMarker,
    activeMarker,
    baseUrl,
    marker: { id, name, position, images, eventDateUtc },
}) => {
    const [imageAIResponses, setImageAIResponses] = useState({});
    const [activeSlide, setActiveSlide] = useState(0);
    const [loading, setLoading] = useState(false);

    return (
        <Marker
            key={id}
            position={position}
            onClick={() => {
                handleActiveMarker(id);
            }}
        >
            {activeMarker === id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>
                        <Carousel

                            className={loading ? "carousel-disabled" : "carousel"}
                            style={{ width: "350px", height: "325px" }}
                            onTransitionEnd={(e) => setActiveSlide(e.currentIndex)}
                            infinite={images.length > 1}
                            organicArrows={false}
                            buttonContentRight={
                                <IconChevronRight className="icon-chevron-right" />
                            }
                            buttonContentLeft={
                                <IconChevronLeft className="icon-chevron-left" />
                            }
                            mobileTouch
                        // selected={images.length > 2 ? Math.ceil(images.length / 2) : 0}
                        >
                            {images.map((aiImage) => (                           
                                <div style={{ background: "white", textAlign: "center" }} >
                                    <img alt=""
                                        src={baseUrl + aiImage.image}
                                        style={{ width: "350px", height: "auto" }}
                                    />
                                    <p style={{ paddingTop: "5px" }}>
                                        {aiImage.aiResponse}
                                    </p>
                                    <p style={{ paddingTop: "5px" }}>
                                        {position.lat + ', ' + position.lng}
                                    </p>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </InfoWindow>
            ) : null}
        </Marker>
    );
};

function Map({
    id,
    baseUrl,
    markers,
    route,
    flow,
    markerType,
    activeMarker,
    setActiveMarker,
    setMarkerType,
    isRunOver,
    isAiHeatmap,
    mapStyle,
    polygons,
}) {
    const runDataFromRedux = useSelector(selectRouteById(id));

    const [zoom, setzoom] = useState(18);
    const [center, setCenter] = React.useState({ lat: 37.851, lng: -98.205 });
    const mapRef = React.useRef(null);
    const [map, setMap] = React.useState(null);

    const polylineOptions = useRef({
        strokeColor: "#FFFF00",
        strokeOpacity: 0.9,
        strokeWeight: 5,
        fillColor: "#FFFF00",
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        // paths: route,
        zIndex: 1,
        icons: [
            {
                icon: {
                    // eslint-disable-next-line no-undef
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeColor: "rgb(254, 220, 86, 1)",
                    fillColor: "rgb(254, 220, 86, 1)",
                    fillOpacity: 1,
                },
                repeat: "100px",
                path: [],
            },
        ],
    });

    const dispatch = useDispatch();

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        try {
            setMap(map);
            /* eslint-disable */
            const bounds = new google.maps.LatLngBounds();
            markers.forEach(({ position }) => bounds.extend(position));
        } catch (error) { }
    };

    const saveMapDataToRedux = (hasMoved) => {
        if ((!markers || markers.length === 0) && (!route || route.length === 0))
            return;

        dispatch(
            addRoute({
                id: id,
                markerType: markerType,
                zoom: map?.zoom,
                keepCentered: hasMoved
                    ? false
                    : hasMoved === false
                        ? true
                        : runDataFromRedux?.keepCentered ?? true,
                center: {
                    lat: map?.center?.lat(),
                    lng: map?.center?.lng(),
                },
            })
        );
    };

    useEffectOnce(() => {
        if (!runDataFromRedux) return;
        setzoom(runDataFromRedux.zoom);

        if (runDataFromRedux?.keepCentered) {
            setCenter(runDataFromRedux.center);
        }
    });

    useEffect(() => {
        if (markers && markers.length > 0) {
            setzoom(runDataFromRedux?.zoom || 18);
        } else if (route && route.length > 0) {
            setzoom(runDataFromRedux?.zoom || 18);
        } else {
            setzoom(runDataFromRedux?.zoom || 3);
        }
    }, [markers, route, runDataFromRedux]);

    useEffectOnce(() => {
        if (markers && markers.length > 0) {
            setMarkerType("weedpressure-heatmap");
        } else if (route && route.length > 0) {
            setMarkerType("route-view");
        }
    }, [markers, route]);

    useEffect(() => {
        // if (!runDataFromRedux) return;
        if (!route && !markers) return;

        if (runDataFromRedux?.keepCentered) {
            setCenter(
                route && route.length > 1
                    ? route[route.length - 1]
                    : markers && markers.length > 1
                        ? markers[markers.length - 1].position
                        : { lat: 37.851, lng: -98.205 }
            );
        } else if (runDataFromRedux && !runDataFromRedux.keepCentered) {
            setCenter(runDataFromRedux.center);
        } else {
            setCenter(route[route.length - 1]);
        }
    }, [runDataFromRedux, route, markers]);

    const debouncedSaveMapDataToRedux = debounce(() => {
        saveMapDataToRedux();
    }, 1000);

    // Should only run once
    useEffect(() => {
        if (!id) return;

        console.log("1");
        debouncedSaveMapDataToRedux();
    }, [id, markerType]);

    const weedPressureHeatMap = useMemo(() => {
        return markers.map(
            ({ position }) =>
                new google.maps.LatLng(
                    parseFloat(position.lat),
                    parseFloat(position.lng)
                )
        );
    }, [markers]);

    function getAiHeatmapData() {
        debugger;
        return markers
            .filter(marker =>
            {                
                return marker?.images.some(image => image.aiResponse.trim() === markerType.trim());
            })
            .map(
            ({ position }) =>
                new google.maps.LatLng(
                    parseFloat(position.lat),
                    parseFloat(position.lng)
                )
        );
    };
   
    const sprayDensityHeatMap = useMemo(() => {
        return flow.map((item) => new google.maps.LatLng(item.lat, item.lng));
    }, [flow]);

    const toggleCenter = () => {
        if (runDataFromRedux?.keepCentered) {
            saveMapDataToRedux(true);
        } else {
            setCenter(route[route.length - 1]);
            saveMapDataToRedux(false);
        }
    };

    const handleDragEnd = () => {
        const newCenter = map.getCenter().toJSON();
        // setCenter(newCenter);
        saveMapDataToRedux(true);
    };
    const ENDPOINT_MARKER =
        "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";

    return (
        <GoogleMap
            zoom={zoom}
            onZoomChanged={debouncedSaveMapDataToRedux}
            // onCenterChanged={debouncedSaveMapDataToRedux}
            onDragEnd={handleDragEnd}
            ref={mapRef}
            options={{
                streetViewControl: false,
                scaleControl: true,
                fullscreenControl: false,
                mapTypeControl: false,
                mapTypeId: google.maps.MapTypeId.SATELLITE,
            }}
            onLoad={handleOnLoad}
            onClick={() => setActiveMarker(null)}
            center={center}
            mapContainerStyle={{ ...containerStyle, ...mapStyle }}
        >
            {polygons
                .filter((i) => i.paths)
                .map((polygon) => {
                    return (
                        <Polygon
                            key={polygon.name}
                            paths={polygon.paths}
                            options={{
                                fillColor: generateRandomColor(polygon.name),
                                fillOpacity: 0.4,
                                strokeColor: generateRandomColor(polygon.name),
                                strokeOpacity: 1,
                                strokeWeight: 1,
                            }}
                        />
                    );
                })}

            {
                markerType === "marker-view" ? (
                    markers
                        .filter((i) => i.images.length)
                        .map((marker) => (
                            <MarkerAI
                                runId={id}
                                marker={marker}
                                handleActiveMarker={handleActiveMarker}
                                setActiveMarker={setActiveMarker}
                                activeMarker={activeMarker}
                                baseUrl={baseUrl}
                            />
                        ))
                ) : markerType === "weedpressure-heatmap" ? (
                    <HeatmapLayer data={weedPressureHeatMap} />
                ) : markerType === "spraydensity-heatmap" ? (
                    <HeatmapLayer data={sprayDensityHeatMap} />
                ) : markerType === "route-view" ? (
                    <>
                        <Polyline path={route} options={polylineOptions.current} />
                        <Marker
                            position={route?.[route.length - 1]}
                            icon={{
                                path: ENDPOINT_MARKER,
                                fillColor: isRunOver ? "red" : "blue",
                                fillOpacity: 0.9,
                                scale: 2,
                                strokeColor: isRunOver ? "red" : "blue",
                                strokeWeight: 2,
                            }}
                        />
                        <Marker
                            position={route?.[0]}
                            icon={{
                                path: ENDPOINT_MARKER,
                                fillColor: "green",
                                fillOpacity: 0.9,
                                scale: 2,
                                strokeColor: "green",
                                strokeWeight: 2,
                            }}
                        />
                    </>
                ) : isAiHeatmap ?
                (
                    <HeatmapLayer data={getAiHeatmapData()} />
                ) : (
                    markers
                        .filter((i) => i.images.length && i.images.some(image => image.aiResponse.trim() === markerType.trim()))
                        .map((marker) => (
                            <MarkerAI
                                runId={id}
                                marker={marker}
                                handleActiveMarker={handleActiveMarker}
                                setActiveMarker={setActiveMarker}
                                activeMarker={activeMarker}
                                baseUrl={baseUrl}
                            />
                        ))
                )} 

            <ActionIcon
                style={{
                    position: "absolute",
                    bottom: "280px",
                    backgroundColor: "#FFF",
                    color: "#000",
                    right: "10px",
                }}
                variant="filled"
                aria-label="Settings"
                size={40}
                onClick={toggleCenter}
            >
                <IconCurrentLocation
                    style={{
                        width: "70%",
                        height: "70%",
                        color: runDataFromRedux?.keepCentered ? "green" : "grey",
                    }}
                    stroke={1.5}
                />
            </ActionIcon>
        </GoogleMap>
    );
}

export default Map;
